import React from "react";
import { useRedirectHandler } from "@src/utils/useRedirectHandler";
import SEO from "@src/components/SEO";
import queryString from "query-string";

const JoinGroup = () => {
  useRedirectHandler(() => {
    const parsedLocation = queryString.parse(window?.location?.search || "");
    const { inviteCode } = parsedLocation;
    if (!inviteCode) {
      return `https://home.classdojo.com/#/story`;
    }
    return `https://home.classdojo.com/#/communities/join-group/${inviteCode}`;
  });

  return (
    <>
      <SEO noindex={true} />
    </>
  );
};

export default JoinGroup;
